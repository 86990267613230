import React from "react"
import styled from "styled-components"

export default function Spotify({ node }) {
  if (!node.uri) {
    return null
  }

  // Size presets, defined by Spotify
  const sizePresets = {
    large: {
      width: 300,
      height: 240, // spotify-recommended: 380
    },
    compact: {
      width: 300,
      height: 80,
    },
  }

  let size = sizePresets["compact"]

  if (node.size) {
    if (typeof node.size === "string") {
      size = sizePresets[node.size]
    } else {
      size = node.size
    }
  }

  return (
    <FigureWrapper>
      <iframe
        title={node.name || "Spotify Player"}
        src={`https://embed.spotify.com/?uri=${node.uri}`}
        width={size.width}
        height={size.height}
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
      />

      {node.name && (
        <figcaption>
          {node.name}
          {node.creator && <> &mdash; {node.creator}</>}
        </figcaption>
      )}
    </FigureWrapper>
  )
}

const FigureWrapper = styled.figure`
  margin: 3rem -1.5rem;
  text-align: center;
  display: block;
  overflow: hidden;

  @media only screen and (min-width: 651px) {
    iframe {
      border-radius: 5px;
    }
  }

  figcaption {
    margin-top: 0.75em;
    font-size: 0.8em;
    text-align: center;
    font-style: italic;
    color: var(--gray);
  }
`
