import React from "react"
import { Link } from "gatsby"
import PortableText from "@sanity/block-content-to-react"
import PortableTextRenderer from "./PortableTextRenderer"
import {
  portableToPlainText,
  calculateTimeToRead,
  generatePath,
  generateNameWithHonorific,
} from "../lib/helpers"
import FadeIn from "./FadeIn"
import styled from "styled-components"

import Figure from "./article/Figure"
import Video from "./article/Video"
import Spotify from "./article/Spotify"
import BlockQuote from "./article/BlockQuote"
import PullQuote from "./article/PullQuote"
import Note from "./article/Note"
import Footnote from "./article/Footnote"
import Footnotes from "./article/Footnotes"
import Accordion from "./Accordion"
import ContactForm from "./ContactForm"

export default function Article({ article, previousArticle, nextArticle }) {
  const serializers = {
    marks: {
      internalLink: ({ mark, children }) => {
        const { type, slug = {}, url } = mark
        const href = url ? url : `/${type}/${slug.current}`
        return <a href={href}>{children}</a>
      },

      externalLink: ({ mark, children }) => {
        const { blank, href } = mark
        return blank ? (
          <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        ) : (
          <a href={href} rel="noopener noreferrer">
            {children}
          </a>
        )
      },

      footnote: Footnote,
    },
    types: {
      centeredText: ({ node }) => {
        const { text } = node

        return (
          <Centered>
            <PortableText blocks={text} />
          </Centered>
        )
      },

      blockQuote: ({ node }) => {
        const { text, source } = node

        return (
          <BlockQuote source={source}>
            <PortableText blocks={text} />
          </BlockQuote>
        )
      },

      pullQuote: ({ node }) => {
        const { text, source } = node

        return (
          <PullQuote source={source}>
            <PortableText blocks={text} />
          </PullQuote>
        )
      },

      note: ({ node }) => {
        const { text } = node

        return (
          <Note>
            <PortableText blocks={text} />
          </Note>
        )
      },

      figure: Figure,
      video: Video,
      spotify: Spotify,

      accordion: ({ node }) => {
        const { title, content } = node

        return (
          <Accordion title={title}>
            <PortableTextRenderer text={content} />
          </Accordion>
        )
      },

      contactForm: ({ node }) => {
        const { title, recipientName, recipientEmail } = node

        return (
          <ContactForm
            title={title}
            recipientName={recipientName}
            recipientEmail={recipientEmail}
          />
        )
      },
    },
  }

  const footnotes =
    article._rawContent &&
    article._rawContent
      // filter out everything that's not a text block
      .filter(({ _type }) => _type === "block")
      // make an array of the mark definitions of those blocks
      .reduce((acc, curr) => {
        return [...acc, ...curr.markDefs]
      }, [])
      // find all the footnote mark definitions
      .filter(({ _type }) => _type === "footnote")

  return (
    <>
      <FadeIn direction="up">
        {article.edition.status === "archived" && (
          <ArchivedMessage>
            <p>
              All articles published in the {article.edition.name} edition have
              been archived.
            </p>
          </ArchivedMessage>
        )}
        <Lede>
          <ArticleTitle>{article.title}</ArticleTitle>
          <Author>
            <span>
              by{" "}
              <Link to={generatePath(article.author.slug.current, "person")}>
                <b>
                  {generateNameWithHonorific(
                    article.author.name,
                    article.author.honorific
                  )}
                </b>
              </Link>
            </span>
            {article.authorSuffix ? ", " + article.authorSuffix : ""}
          </Author>
        </Lede>
      </FadeIn>

      <FadeIn direction="down" delay={250}>
        <MetaData>
          <p>
            {calculateTimeToRead(portableToPlainText(article._rawContent))} min.
            read &mdash;{" "}
            <Link to={generatePath(article.edition.generatedSlug, "edition")}>
              {article.edition.release}
            </Link>
          </p>
        </MetaData>
      </FadeIn>

      <FadeIn direction="up" duration={750} delay={500}>
        <Content>
          <PortableText
            blocks={article._rawContent}
            serializers={serializers}
          />

          {footnotes && footnotes.length > 0 && (
            <Footnotes blocks={article._rawContent} />
          )}
        </Content>
      </FadeIn>

      {(previousArticle || nextArticle) && (
        <FadeIn direction="up" duration={350} delay={1000}>
          <ArticleNavigation
            className={previousArticle && nextArticle ? "twoColumns" : ""}
          >
            {previousArticle && (
              <Link
                to={generatePath(previousArticle.slug.current, "article")}
                className="custom previous"
              >
                <span>Previous:</span>
                <p>{previousArticle.title}</p>
              </Link>
            )}
            {nextArticle && (
              <Link
                to={generatePath(nextArticle.slug.current, "article")}
                className="custom next"
              >
                <span>Next:</span>
                <p>{nextArticle.title}</p>
              </Link>
            )}
          </ArticleNavigation>
        </FadeIn>
      )}
    </>
  )
}

const ArchivedMessage = styled.div`
  margin: -1rem 0 1rem;
  padding: 1rem 1.5rem;
  background-color: rgba(var(--maroon-rgb), 0.065);
  border-radius: 5px;
  border-left: 5px solid var(--maroon);
`

const Lede = styled.div`
  overflow: hidden;
`

const ArticleTitle = styled.h1`
  display: inline;
  vertical-align: middle;
  margin-right: 1rem;
`

const Author = styled.p`
  display: block;
  margin-top: 0.25rem;
  color: var(--gray);
  overflow: hidden;

  > span {
    white-space: nowrap;
  }

  &:after {
    content: "";
    display: inline-block;
    height: 0.225em;
    vertical-align: middle;
    width: 100%;
    margin-right: -100%;
    margin-left: 1em;
    border-top: 1px solid var(--gray);
  }

  @media only screen and (min-width: 767px) {
    display: inline;
    vertical-align: middle;
    margin-top: 0;
  }
`

const MetaData = styled.div`
  margin: 1rem 0 3rem 0;
  border-radius: 0 0 5px 5px;
  text-align: right;
  font-size: 0.9em;

  p {
    margin: 0 0 0.25em 0;
    color: var(--gray);
  }

  @media only screen and (min-width: 767px) {
    margin: 0 0 3rem 0;
  }
`

const Content = styled.div`
  line-height: 1.65;
  font-size: 1.15em;
  counter-reset: footnote-counter;

  blockquote > p:first-of-type {
    &::before {
      content: "“";
    }

    &::after {
      content: "”";
    }
  }

  ul,
  ol {
    margin-left: 2.5rem;
    margin-right: 1.25rem;
  }

  ol li {
    padding-left: 0.5rem;
  }

  li {
    line-height: 1.55;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    &::marker {
      color: var(--maroon);
    }
  }

  & > * > p {
    margin: 2rem 0;

    &:first-of-type:first-letter {
      color: var(--gray);
      float: left;
      font-family: "alexander-quill", Georgia, "Times New Roman", Times, serif;
      font-size: 4em;
      line-height: 50px;
      padding-top: 5px;
      padding-right: 10px;
      padding-left: 3px;
    }

    &:last-of-type:after {
      content: " •";
      display: inline;
      white-space: nowrap;
      color: var(--gray);
    }
  }

  .footnote-ref {
    &:before {
      content: "[";
      color: var(--gray);
    }

    span:before {
      counter-increment: footnote-counter;
      content: counter(footnote-counter);
      color: var(--gray);
      transition: color 0.1s ease;
    }

    &:after {
      content: "]";
      color: var(--gray);
    }

    &:hover span:before {
      color: var(--maroon);
    }

    &:focus {
      outline: 2px dashed var(--gold);
    }
  }

  hr {
    margin: 2rem 0;
    border: 1px solid var(--gray);
  }
`

const Centered = styled.div`
  text-align: center;
`

const ArticleNavigation = styled.div`
  margin-top: 5rem;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 100%;

  @media only screen and (min-width: 767px) {
    margin: 5rem -1.5rem 0;
  }

  &.twoColumns > a {
    &:first-of-type {
      margin-bottom: -1px;
      border-radius: 5px 5px 0 0;
    }
    &:last-of-type {
      margin-top: -1px;
      border-radius: 0 0 5px 5px;
    }
  }

  &.twoColumns {
    @media only screen and (min-width: 767px) {
      grid-template-columns: repeat(2, 1fr);

      & > a:first-of-type {
        text-align: left;
        border-radius: 5px 0 0 5px;
        margin-right: -1px;
        margin-bottom: 0;
      }

      & > a:last-of-type {
        text-align: right;
        border-radius: 0 5px 5px 0;
        margin-left: -1px;
        margin-top: 0;
      }
    }
  }

  a {
    display: block;
    border: 2px solid var(--maroon);
    padding: 1.5rem;
    color: var(--maroon);
    transition: all 0.15s ease;
    border-radius: 5px;
    text-align: center;

    &:hover,
    &:focus {
      background-color: var(--maroon);
      color: var(--light100);

      span {
        color: var(--light100);
      }
    }

    span {
      display: block;
      margin-bottom: 0.25rem;
      color: var(--gray);
    }

    p {
      font-family: var(--font-serif);
      font-size: 1.4em;
    }
  }
`
