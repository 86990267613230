import React from "react"
import { graphql } from "gatsby"
import WebsiteMeta from "../components/WebsiteMeta"
import Article from "../components/Article"
import {
  buildScreenshotLink,
  portableToPlainText,
  generateExcerpt,
} from "../lib/helpers"
import styled from "styled-components"

export default function Template({ data, pageContext }) {
  const { article, previousArticle, nextArticle } = data
  const excerpt = generateExcerpt(portableToPlainText(article._rawContent), 150)

  // Generate social media preview
  const { siteUrl } = data.site.siteMetadata
  const { currentSlug } = pageContext
  const buildTimeUrl = `${siteUrl}${currentSlug}`
  const buildTimeUrlScreenshot = `${buildTimeUrl}/screenshot`
  const metaImage = buildScreenshotLink(buildTimeUrlScreenshot)

  return (
    <>
      <WebsiteMeta
        title={article.title}
        description={excerpt || null}
        image={metaImage}
        imageDimensions={[1200, 630]}
      />
      <ArticleWrapper>
        <Article
          article={article}
          previousArticle={previousArticle}
          nextArticle={nextArticle}
        />
      </ArticleWrapper>
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!, $previousId: String, $nextId: String) {
    article: sanityArticle(_id: { eq: $id }) {
      _id
      title
      status
      edition {
        name
        status
        release(formatString: "MMMM D, YYYY")
        generatedSlug: release(formatString: "YYYY/MM")
      }
      author {
        honorific
        name
        slug {
          current
        }
      }
      authorSuffix
      _rawContent(resolveReferences: { maxDepth: 10 })
    }

    site {
      siteMetadata {
        siteUrl
      }
    }

    previousArticle: sanityArticle(_id: { eq: $previousId }) {
      _id
      slug {
        current
      }
      title
      status
    }

    nextArticle: sanityArticle(_id: { eq: $nextId }) {
      _id
      slug {
        current
      }
      title
      status
    }
  }
`

const ArticleWrapper = styled.article`
  width: min(65ch, 100%);
  margin: 0 auto;
  transition: all 0.25s ease-in-out;

  @media only screen and (min-width: 767px) {
    padding-top: 0;
  }
`
