import React from "react"
import PortableText from "@sanity/block-content-to-react"
import Accordion from "../Accordion"
import styled from "styled-components"

export default function SanityFootnote({ blocks }) {
  const serializers = {
    marks: {
      externalLink: ({ mark, children }) => {
        const { blank, href } = mark
        return blank ? (
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="custom footnote-link"
          >
            {children}
          </a>
        ) : (
          <a
            href={href}
            rel="noopener noreferrer"
            className="custom footnote-link"
          >
            {children}
          </a>
        )
      },
    },
    types: {},
  }

  const footnotes = blocks
    // filter out everything that's not a text block
    .filter(({ _type }) => _type === "block")
    // make an array of the mark definitions of those blocks
    .reduce((acc, curr) => {
      return [...acc, ...curr.markDefs]
    }, [])
    // find all the footnote mark definitions
    .filter(({ _type }) => _type === "footnote")

  return (
    <Wrapper>
      <Accordion title="Footnotes" open={true} id="footnotes">
        <ol>
          {footnotes.map(({ _key, text }) => (
            <li id={`fn-${_key}`} key={_key}>
              <PortableText blocks={text} serializers={serializers} />
              <a href={`#fnref-${_key}`} className="custom footnote-backref">
                <span aria-label="Jump to reference">&uarr;</span>
              </a>
            </li>
          ))}
        </ol>
      </Accordion>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 2.5rem auto;

  ol li {
    font-size: 0.8em;
    line-height: 1.45;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;

    * {
      display: inline;
    }

    .footnote-link {
      color: var(--maroon);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .footnote-backref {
      margin-left: 0.325em;
      color: var(--maroon);

      span {
        display: inline-block;
        will-change: transform;
        transition: transform 0.2s ease-in;
      }

      &:hover {
        span {
          transform: translateY(-0.2em) scale(1.05);
          transition: transform 0.15s ease;
        }
      }

      &:focus {
        outline: 2px solid var(--gold);
      }
    }
  }
`
