import React from "react"

export default function Footnote({ children, markKey }) {
  return (
    <span>
      {children}
      <sup id={`fnref-${markKey}`}>
        <a
          href={`#fn-${markKey}`}
          className="custom footnote-ref"
          title="Footnote"
        >
          <span></span>
        </a>
      </sup>
    </span>
  )
}
