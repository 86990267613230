import React from "react"
import styled, { keyframes } from "styled-components"

export default function Accordion({ title, open, children }) {
  return (
    <Wrapper>
      <details open={open || false}>
        <summary>
          <h2>{title}</h2>
        </summary>
        <Content>{children}</Content>
      </details>
    </Wrapper>
  )
}

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const Wrapper = styled.section`
  overflow: hidden;
  margin: 0;

  summary {
    background-color: rgba(var(--maroon-rgb), 0.065);
    border: 3px solid transparent;
    border-radius: 5px;
    padding: 0.5rem 1.5rem 0.5rem;
    outline: none;
    color: var(--maroon);
    cursor: pointer;
    user-select: none;
    transition: all 0.325s ease;

    &:focus-visible {
      border: 3px solid var(--gold);
    }

    > * {
      display: inline;
      color: var(--maroon);
    }
  }
  @media only screen and (min-width: 767px) {
    margin: 0 -1.5rem;
  }

  [open] summary {
    border-radius: 5px 5px 0 0;
    transition: all 0.325s ease-out;

    > * {
      color: var(--maroon);
    }
  }
`

const Content = styled.div`
  padding: 1.25rem 0.25rem 1.5rem;
  border: 2px solid rgba(var(--maroon-rgb), 0.065);
  border-top: none;
  border-radius: 0 0 5px 5px;
  animation: ${fadeInAnimation} 0.5s forwards;
`
