import React from "react"
import styled from "styled-components"

export default function ContactForm({
  title,
  subtitle,
  recipientName,
  recipientEmail,
}) {
  const url = typeof window !== "undefined" ? window.location.href : ""

  const recipient = {
    name: recipientName,
    email: recipientEmail,
  }

  return (
    <Wrapper>
      {title && <Title>{title}:</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Form
        name="Contact Form"
        method="POST"
        action="/success/"
        netlify-honeypot="honeypot-field"
        data-netlify="true"
        formHasATitle={title ? true : false}
      >
        <input type="hidden" name="form-name" value="Contact Form" />
        <input type="hidden" name="source" value={url} />
        <input type="hidden" name="recipient-name" value={recipient.name} />
        <input type="hidden" name="recipient-email" value={recipient.email} />

        <label style={{ display: `none` }}>
          Don’t fill this out if you’re human:{" "}
          <input type="text" name="honeypot-field" />
        </label>

        <label>
          Your Name:
          <input type="text" name="name" placeholder="John Doe" required />
        </label>
        <label>
          Your Email:
          <input
            type="email"
            name="email"
            placeholder="john.doe@example.com"
            required
          />
        </label>
        <label>
          Your Message:
          <textarea
            name="message"
            minLength="15"
            placeholder="Share your thoughts..."
            required
          />
        </label>

        <Footer>
          <button type="submit" className="full focus icon">
            Send Message
            <span>&rarr;</span>
          </button>
          <Disclaimer>
            Your comments will be visible to the Priory Press team
          </Disclaimer>
        </Footer>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 2px solid var(--maroon);
  border-radius: 5px;

  @media only screen and (min-width: 767px) {
    margin: 0 -1.5rem 0 -1.5rem;
  }
`

const Title = styled.h2`
  margin: 1rem 1.5rem 0;
`

const Subtitle = styled.p`
  margin: 0.5rem 1.5rem 1.5rem;
`

const Form = styled.form`
  padding: ${props =>
    props.formHasATitle ? "0.5rem 1.5rem 1rem" : "1.25rem 1.5rem 1rem"};
`

const Footer = styled.footer`
  margin-top: 0.75rem;
`

const Disclaimer = styled.p`
  margin: 0.5rem auto 0;
  color: var(--accent);
  font-size: 0.75em;
  text-align: center;
`
