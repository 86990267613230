import React from "react"
import styled from "styled-components"
import InfoCircle from "../svg/InfoCircle"

export default function PullQuote({ children }) {
  return (
    <Wrapper>
      <Icon>
        <InfoCircle />
      </Icon>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.aside`
  --border-width: 4px;
  position: relative;
  margin: 0;
  padding: 2rem 1.5rem calc(1.5rem - var(--border-width));
  background-color: rgba(var(--maroon-rgb), 0.065);
  border-radius: 0 var(--border-width) var(--border-width) var(--border-width);
  border-left: var(--border-width) solid var(--maroon);
  text-align: left;
  color: var(--page-color);
  transition: all 0.15s ease-in-out;

  @media only screen and (min-width: 767px) {
    margin: 0 -1.5rem;
  }

  p {
    margin: 0;
    line-height: 1.55;
    font-size: 0.925em;

    & ~ {
      h2,
      h3,
      h4 {
        margin-top: 1.25rem;
      }
    }
  }
`

const Icon = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(-50%, -50%);
  padding: 0.5rem;
  background: var(--page-background);
  border-radius: 50%;
  line-height: 1;
  color: var(--maroon);

  svg {
    height: 2rem;
    width: 2rem;
  }
`
